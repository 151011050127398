<template>
  <v-container>
    <v-btn fab color="primary" @click="showAddDialog = true">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <div class="mt-5">
      <v-card
        class="mt-2"
        @click="$router.push(`/tiimi-admin/leagues/${league.id}/games`)"
        v-for="league in leagues"
        :key="league.id + 'league'"
      >
        <v-card-title>
          {{league.league_name}}
        </v-card-title>
      </v-card>
    </div>
    <v-dialog
      v-model="showAddDialog"
      @click:outside="showAddDialog = false"
      max-width="700"
    >
      <v-card>
        <v-card-text>
          <v-card-title>Create league</v-card-title>
          <v-form>
            <v-text-field label="League name" solor>
            </v-text-field>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    showAddDialog: false,
  }),
  computed: {
    ...mapGetters('admin', [
      'leagues',
      'games'
    ])
  }
}
</script>